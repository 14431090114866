.navbar__container{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    width: 100%;
}
.navbar__container.visible{
    background: var(--color-ui-2);
    position: sticky;
}
.navbar__container .logo__container{
    display: flex;
    align-items:flex-end ;
    gap: 15px;
    cursor: pointer;
    font-size: 35px;
}

.navbar__container .tab__group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.navbar__container .tab__item{
    position: relative;
    padding: 10px 20px ;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}
.navbar__container .tab__item:hover{
    color: var(--color-primary);
    transform: scale(1.1);
    transition: smooth;
}

.navbar__container .tab__item.active::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--gradient-1);
    border-radius: var(--border-radius-4);
    animation: drawer .5s;
}

@keyframes drawer{
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}

.navbar__container .nav__buttons__group{
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar__container .menu{
    display: none;
    cursor: pointer;
    font-size: 25px;
}

.navbar__container .close__btn{
position: absolute;
top: 10px;
right: 10px;
z-index: 2;
font-size: 16px;
display: none;
}

.navbar__container .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(1,1,1,0.5);
    z-index: 199;
    user-select: none;
    cursor: pointer;
}

@media (max-width: 900px){
    .navbar__container{
        background: var(--color-ui-2);
        
    }

    .navbar__container .tab__group{
        
        position: fixed;
        width: 300px;
        height: 100%;
        min-height: 100vh;
        background: var(--color-ui-1);
        top:0 ;
        left: -100%;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        z-index: 200;
        transition: var(--transition);
        padding: 20px;
    }

    .navbar__container .tab__group.show{
        left: 0;
    }
    .navbar__container .menu{
        display: block;
    }
    .navbar__container .close__btn{
        display: flex;
        }
    
}