

#header {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    height: auto;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px; 
}

.column {
    flex: 1;
    padding: 20px;
}

.intro__container {
    flex: 1; 
}

.profile__wrapper {
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__info__top {
    font-size: 15px;
    font-weight: 500;
}

.header__title {
    font-size: 48px; 
    font-weight: 900;
}

.header__description {
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0;
}

.header__info__bottom {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.profile__photo__container {
    width: 400px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.profile__photo {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 6px;
}

/*responsiveness*/
@media (max-width: 768px) {
    .header__container {
        flex-direction: column;
        text-align: center;
    }

    .intro__container, .profile__wrapper {
        flex: none;
        width: 100%;
    }

    .header__title {
        font-size: 36px;
    }

    .header__info__bottom {
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
    }

    .profile__photo__container {
        width: 300px;
    }
}

@media (max-width: 480px) {
    .header__title {
        font-size: 28px;
    }

    .header__info__top, .header__description {
        font-size: 14px;
    }

    .profile__photo__container {
        width: 250px;
    }
}