@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --color-primary: #8750f7;
    --color-secondary: rgba(28, 20, 5, 0.773);
    --color-text: #fff;
    --color-muted: #9fa5a5;
    --color-red: red;
    --color-success: #33c648;
    --color-white:#fff;
    --color-ui-1:rgb(1,1,1);
    --color-ui-2: rgb(24, 25, 25);
    --color-off-white:#f6f3fc;
    --transition:all 400ms ease;
    --gray-border:solid 0.5px lightgray;
    --border-radius-1: 4px;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;
    --border-radius-4: 2rem;
    --border-radius-5: 51px;
    --gradient-1: linear-gradient(260deg, var(--color-secondary) 0%, var(--color-primary) 100%);
    --card-rgb:180, 185, 188;
    --card-border-rgb:131, 134, 135;
}

*{
    margin: 0;
    padding: 0;
    scrollbar-color: var(--color-primary) var(--color-secondary);
    box-sizing: border-box;
    scrollbar-width: thin;
    list-style: none;
    border: 0;
    outline: 0;
}

::-webkit-scrollbar{
    width: 4px;
    height: 4px;
    background: var(--gradient-1);
}

::-webkit-scrollbar-thumb{
    --webkit-border-radius: 4px;
    --webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
}

::-webkit-scrollbar-corner{
    background: transparent;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-ui-1);
    color: var(--color-text);
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior : smooth;
}

a{
    text-decoration: none;
    color: var(--color-text);
    display: block;
}

h1,h2,h3,h4,h5,h6{
    font-weight: 500;
}

img{
    width: 100%;
    min-height: auto;
    object-fit: cover;
}

.btn{
    display: inline-block;
    padding: 15px 25px;
    border-radius: var(--border-radius-5);
    background: var(--color-ui-2);
    color: var(--color-text);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: var(--transition);
}

.btn__primary{
    background: var(--gradient-1);
    color: var(--color-white);
    background-size: 200% auto;
    transition: var(--transition);
}

.btn__primary:hover{
    background-position: right center;
}

.color__primary{
    color: var(--color-primary);
}

.text__muted{
    color: var(--color-muted);
}

.stroke__text{
    --webkit-text-stroke: .5px rgba(212,212,214,0.176);
    --webkit-text-fill-color: transparent;
}

.primary__title{
    font-size: 55px;
    font-weight: 900;
    margin: 20px 0;
    background: linear-gradient(to right, #8750f7, white);
    --webkit-background-clip: text;
    --webkit-text-fill-color: transparent;
    background-clip: text;
    color: var(--color-primary);
}

.section__wrapper{
    max-width: 1100px;
    margin: auto;
    padding: 80px 20px;
}

.icon__container{
    width: 2.5rem;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-5);
    cursor: pointer;
    color: var(--color-muted);
    gap: 20px;
}

.icon__container:hover{
    background: var(--color-ui-2);
    transform: scale(1.2);
    color: var(--color-white);
}

.blur-effect{
    position: relative;
}

.blur-effect::before{
    content: '';
    position: absolute;
    width: 322px;
    height: 300px;
    background: var(--color-primary);
    background: -0-linear-gradient(190deg, var(--color-primary) 0%, rgba(115, 67, 210, 0) 100%);
    background: linear-gradient(190deg, var(--color-primary) 0%, rgba(115, 67, 210, 0) 100%);
    --webkit-filter: blur(150px);
    filter: blur(150px);
    z-index: 1;
}

.section__header{
    width: 100%;
    margin-bottom: 20px;
}

.section__header.center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.section__header.center .primary__title, 
.section__header.center .description{
    text-align: center;
}

.section__header.center .description{
    max-width: 900px;
}

/*responsive design*/
@media (max-width: 1100px){
    .primary__title{
        font-size: 32px;
    }
}
@media (max-width: 950px){
    .section__header{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .section__header .title,
    .section__header .primary__title{
        text-align: center;
    }

    .section__header .description{
        margin: auto;
        text-align: center;
    }
    
    }
    

    /*odometer styling*/
    
.odometer.odometer-auto-theme, .odometer.odometer-theme-digital {
    display: inline-block;
    vertical-align: middle;
    /* *vertical-align: auto;
    *zoom: 1;
    *display: inline; */
    position: relative;
    font-size:60px ;
    font-weight: 900;
  }
  .odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-digital .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    /* *vertical-align: auto;
    *zoom: 1;
    *display: inline; */
    position: relative;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-digital .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    /* *vertical-align: auto;
    *zoom: 1;
    *display: inline; */
    visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-digital .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-digital .odometer-digit .odometer-ribbon {
    display: block;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-digital .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-digital .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-digital .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
  }
  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
  }
  .odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  
  .odometer.odometer-auto-theme, .odometer.odometer-theme-digital {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhiZjVhNSIgc3RvcC1vcGFjaXR5PSIwLjQiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background-size: 100%;
    background-image: -moz-radial-gradient(rgba(139, 245, 165, 0.4), #000000);
    background-image: -webkit-radial-gradient(rgba(139, 245, 165, 0.4), #000000);
    background-image: radial-gradient(rgba(139, 245, 165, 0.4), #000000);
    background-color: #000;
    font-family: "Wallpoet", monospace;
    padding: 0 0.2em;
    line-height: 1.1em;
    color: #8bf5a5;
  }
  .odometer.odometer-auto-theme .odometer-digit + .odometer-digit, .odometer.odometer-theme-digital .odometer-digit + .odometer-digit {
    margin-left: 0.1em;
  }
  

  /*responsiveness*/
  @media (max-width: 1100px){
    .odometer.odometer-auto-theme, .odometer.odometer-theme-digital {
        font-size: 32px;
    }
  }

  @media (max-width: 500px){
    .odometer.odometer-auto-theme, .odometer.odometer-theme-digital {
        font-size: 20px;
    }
  }