/* #testimonial{
    display: grid;
    gap: 30px;
    grid-template-columns: 35% 60%;
}

#testimonial .testimonial__container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
}
#testimonial .client__avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 1rem;
    border: var(--gray--border);
}

#testimonial .testimonial{
    background: var(--color-ui-2);
    text-align: center;
    padding: 20px;
    border-radius: var(--border-radius-4);
    user-select: none;
}

#testimonial .client__review{
    font-size: 15px;
    font-weight: 300;
    color: var(--color-text);
    margin: 0.8rem auto 0;
    display: block;
    width: 100%;
    text-align: left;
}

.swiper-pagination-clickable .swiper-pagination-bullet{
    background: var(--color-primary);
    
}

@media(min-width: 768px) {
    #testimonial .testimonial__container {
        grid-template-columns: repeat(2, 1fr);
    }
    #testimonial .client__review {
        width: 80%;
        text-align: center;
}
}


@media(max-width: 768px){
    #testimonial .testimonial__container{
        grid-template-columns: 1fr 1fr;
    }
    #testimonial .client__review {
        width: 100%;
        font-size: 14px;
    }
    #testimonial .testimonial {
        text-align: left;
        padding: 15px;
    }
} */

#testimonial {
    display: block;
}

#testimonial .testimonial__container {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr; /* Default to 1 column */
}

#testimonial .client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 1rem;
    border: var(--gray--border);
}

#testimonial .testimonial {
    background: var(--color-ui-2);
    text-align: center;
    padding: 15px;
    border-radius: var(--border-radius-4);
    user-select: none;
}

#testimonial .client__review {
    font-size: 15px;
    font-weight: 300;
    color: var(--color-text);
    margin: 0.8rem auto 0;
    display: block;
    width: 100%; /* Full width on small screens */
    text-align: left; /* Left-align on small screens */
}

@media(min-width: 768px) {
    #testimonial .testimonial__container {
        grid-template-columns: 1fr 1fr; /* 2 columns for medium screens */
    }

    #testimonial .client__review {
        width: 80%; /* Narrower on larger screens */
        text-align: center; /* Center-align on larger screens */
    }
}
